@font-face {
  font-family: DM Sans;
  src: local("DM Sans Bold"), url("../fonts/Font/DMSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: DM Sans;
  src: local("DM Sans Medium"), url("../fonts/Font/DMSans-Medium.ttf") format("truetype"),
    local("DM Sans Regular"), url("../fonts/Font/DMSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
