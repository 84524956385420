.bouncing-loader-green,
.bouncing-loader-blue {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.bouncing-loader-green > div {
  width: 10px;
  height: 10px;
  margin: 5px;
  border-radius: 50%;
  background-color: #494848;
  opacity: 1;
  animation: bouncing-loader-green 0.6s infinite alternate;
  flex-shrink: 0;
}

@keyframes bouncing-loader-green {
  to {
    opacity: 0.1;
    transform: translateY(-7px);
  }
}

.bouncing-loader-green > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader-green > div:nth-child(3) {
  animation-delay: 0.4s;
}

.bouncing-loader-blue {
  border: 2px solid #3589e9;
  border-radius: 50%;
  background-color: white;
}

.bouncing-loader-blue > div {
  width: 8px;
  height: 8px;
  margin: 4px;
  border-radius: 50%;
  background-color: #3589e9;
  opacity: 1;
  animation: bouncing-loader-blue 0.6s infinite alternate;
  flex-shrink: 0;
}

@keyframes bouncing-loader-blue {
  to {
    opacity: 0.1;
    transform: translateY(-7px);
  }
}

.bouncing-loader-blue > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader-blue > div:nth-child(3) {
  animation-delay: 0.4s;
}

@media (min-width: 600px) and (max-width: 960px) {
  .bouncing-loader-green > div,
  .bouncing-loader-blue > div {
    width: 12px;
    height: 12px;
  }
}

@media (min-width: 960px) {
  .bouncing-loader-green > div,
  .bouncing-loader-blue > div {
    width: 14px;
    height: 14px;
  }
}
