.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  -webkit-animation: linear infinite;
  animation: linear infinite;
  -webkit-animation-duration: 6s;
  -webkit-animation-name: MyDot;
  animation-name: MyDot;
  animation-duration: infinite;
  animation-timing-function: ease-in;
  animation-direction: reverse;
}

.dot-mobile {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  animation-name: MyDotMobile;
  -webkit-animation: linear infinite;
  animation: infinite;
  -webkit-animation-duration: 6s;
  -webkit-animation-name: MyDotMobile;
  animation-duration: 6s;
  animation-timing-function: ease-in;
  animation-direction: normal;
}

.dot-mobile-upward {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  animation: MyDotUpward;
  animation-duration: 5s;
  animation-iteration-count: 2;
  animation-timing-function: ease-in;
  animation-direction: normal;
}

.data-upward {
  /* width: 12px;
  height: 12px; */
  /* border-radius: 50%; */
  position: absolute;
  animation: MyDataUpward;
  animation-duration: 5s;
  animation-iteration-count: 2;
  animation-timing-function: ease-in;
  /* animation-direction: normal; */
}

.dot-forward {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  animation: MyDot;
  animation-duration: 5s;
  animation-iteration-count: 2;
  animation-timing-function: ease-in;
  animation-direction: normal;
}

.logo-img {
  /* to black */
  filter: invert(1);
  /* or to #026CE3 */
  filter: invert(0.5) sepia(1) saturate(3) hue-rotate(175deg);
}

@keyframes MyDot {
  0% {
    left: 20%;
    bottom: 45%;
  }
  100% {
    left: 70%;
    bottom: 45%;
  }
}

@keyframes MyDotMobile {
  0% {
    left: 48.5%;
    bottom: 63%;
  }
  100% {
    left: 48.5%;
    bottom: 35%;
  }
}

@keyframes MyDotUpward {
  0% {
    left: 48.5%;
    top: 63%;
  }
  100% {
    left: 48.5%;
    top: 35%;
  }
}
@keyframes MyDataUpward {
  0% {
    left: 30%;
    top: 50%;
  }
  100% {
    left: 30%;
    top: 30%;
  }
}

.thumbnail-component .text {
  position: relative;
  transition: all 3s ease;
}

.animated {
  background-repeat: no-repeat;
  background-position: left top;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}
